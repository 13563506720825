import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editSelfDetails, getPermissionList, deleteSettingList, addCategoryList, getSettingList, addSettingList,updateSettingList, ClearFormSetFutios,editUserList,getAllCategoryList, getSelfDetails, deleteCategoryList,addNewBrand, updateCategoryList,getAllProductList, UpdateProductList, deleteProduct, getSupplierAllList, LoaderStartMain, deleteSupplier, addProductList, UpdateSupplierList, getSupplierDetails, getAllModelList, editBrand, getAllBrandList, getBrandList, deleteBrand, deleteModel, getModelList, addNewModel, editModel, GetBrandModels, getSupplierList, getProductList, addSupplierList, getBrandsWiseModelList, ViewProjectDetails, getCategoryList } from '../../redux/actions/adminActions';

export const ws_response = ({evt, ws}) =>{
    return(async (dispatch, getState) => {
        const ws_onmessage = JSON.parse(evt.data);
        const { device_id, user_id } = getState()?.adminReducers;
        
        // Sw notification setup
        switch(ws_onmessage.url){
            case "profile_update":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(editSelfDetails(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"profile_update",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"profile_update",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "permission_list":
                dispatch(getPermissionList(ws_onmessage?.response));
                break;
            case "product_brand_list":
                dispatch(getAllBrandList(ws_onmessage?.response));
                dispatch(getBrandList(ws_onmessage?.response));
                break;
            case "add_product_brand":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(addNewBrand(ws_onmessage?.response));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_brand",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_brand",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_user":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(editUserList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.response?.data?.id === user_id) {
                        dispatch(getSelfDetails(ws_onmessage?.response?.data));
                    };
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_user",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_user",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_product_brand":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(editBrand(ws_onmessage?.response));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_brand",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_brand",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "delete_product_brand":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(deleteBrand(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.response?.brand_exist_in_model) {
                        toast.error(ws_onmessage?.response?.msg);
                    } else {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_model_list":
                dispatch(getAllModelList(ws_onmessage?.response));
                dispatch(getModelList(ws_onmessage?.response));
                break;
            case "add_product_model":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(addNewModel(ws_onmessage?.response));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_model",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_model",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_product_model":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(editModel(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_model",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_model",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "delete_product_model":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(deleteModel(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                }
                break;
            case "brand_model_list":
                if(ws_onmessage?.response?.status === true) {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(LoaderStartMain(true));
                        dispatch(getBrandsWiseModelList(ws_onmessage?.response));
                        dispatch(GetBrandModels(ws_onmessage?.response));
                        setTimeout(() => {
                            dispatch(LoaderStartMain(false));
                        }, 2000);
                    } else {
                        dispatch(getBrandsWiseModelList(ws_onmessage?.response));
                        dispatch(GetBrandModels(ws_onmessage?.response));
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(LoaderStartMain(true));
                        dispatch(getBrandsWiseModelList(ws_onmessage?.response));
                        dispatch(GetBrandModels(ws_onmessage?.response));
                        setTimeout(() => {
                            dispatch(LoaderStartMain(false));
                        }, 2000);
                    } else {
                        dispatch(getBrandsWiseModelList(ws_onmessage?.response));
                        dispatch(GetBrandModels(ws_onmessage?.response));
                    };
                };
                break;
            case "supplier_list":
                dispatch(getSupplierAllList(ws_onmessage?.response))
                dispatch(getSupplierList(ws_onmessage?.response));
                break;
            case "add_supplier":
                if(ws_onmessage?.response?.status) {
                    dispatch(addSupplierList(ws_onmessage?.response));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_supplier",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_supplier",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "supplier_detail":
                if(ws_onmessage?.response?.status) {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(LoaderStartMain(true));
                        dispatch(getSupplierDetails( ws_onmessage?.response?.data));
                        setTimeout(() => {
                            dispatch(LoaderStartMain(false));
                        }, 2000);
                    };
                } else {
                    dispatch(getSupplierDetails({}));
                };
                break;
            case "update_supplier":
                if(ws_onmessage?.response?.status) {
                    dispatch(UpdateSupplierList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_supplier",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_supplier",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "delete_supplier":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(deleteSupplier(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_list":
                if(ws_onmessage?.request?.DeviceId === device_id) {
                    dispatch(getAllProductList(ws_onmessage?.response))
                    dispatch(getProductList(ws_onmessage?.response));
                };
                break;
            case "add_product":
                if(ws_onmessage?.response?.status) {
                    dispatch(addProductList(ws_onmessage?.response));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_product":
                if(ws_onmessage?.response?.status) {
                    dispatch(UpdateProductList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "get_product_detail":
                if(ws_onmessage?.response?.status) {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(LoaderStartMain(true));
                        dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
                        setTimeout(() => {
                            dispatch(LoaderStartMain(false));
                        }, 3000);
                    };
                } else {
                    dispatch(getSupplierDetails({}));
                };
                break;
            case "delete_product":
                if(ws_onmessage?.response?.status) {
                    dispatch(deleteProduct(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "product_category_list":
                dispatch(getAllCategoryList(ws_onmessage?.response));    
                dispatch(getCategoryList(ws_onmessage?.response));
                break;
            case "add_product_category":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(addCategoryList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_category",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_category",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_product_category":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(updateCategoryList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_category",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_category",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;   
            case "delete_product_category":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(deleteCategoryList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;   
            case "product_settings_list":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(getSettingList(ws_onmessage?.response))
                }
                break;   
            case "add_product_setting":
                if(ws_onmessage?.response?.status) {
                    dispatch(addSettingList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_setting",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"add_product_setting",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "update_product_setting":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(updateSettingList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_setting",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url:"update_product_setting",
                            action:ws_onmessage?.response?.status
                        }));
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            case "delete_product_setting":
                if(ws_onmessage?.response?.status === true) {
                    dispatch(deleteSettingList(ws_onmessage?.response?.data));
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.success(ws_onmessage?.response?.msg);
                    };
                } else {
                    if(ws_onmessage?.request?.DeviceId === device_id) {
                        toast.error(ws_onmessage?.response?.msg);
                    };
                };
                break;
            default:
                return;
        }
    })
};
        
