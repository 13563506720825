/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { PRODUCT_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector,useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Country }  from 'country-state-city';
import Countrycurrency from "../Common/Countrycurrency.json";
import CommonLoader from "../Common/CommonLoader";
import ImportProductAPI from "../../Api/ImportProductAPI";
import BtnLoader from "../Common/BtnLoader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import SampleFile from "../../assets/sample/sample_product.xlsx"
import ExportIcon from "../../assets/img/export_icon.png";
import { getBrandsWiseModelList } from '../../redux/actions/adminActions';

const AddEditProduct = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const params = useParams();
    const Location = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ inputValue, setInputValue ] = useState({ 
        PInumber : "", ProductName : "", 
        InCoterm: "", HSCode: "", Currency: "", Floor: "",
        PS: "", ClientCode: "", PcCode: "", DescriptionFrench: "", DescriptionEnglish: "",
        Department: "", Unit: "", UcpUsd: "", TcpUsd:"", UspUsd:"", TspUsd:"", LotNumber: "",
        Brochure: "", Remarks: "", Catalog: "", CS: "", CE: "", Iso: "", Maf: "", Warranty: "",
        LeadTime: "", Fator: "",
    });
    const { PInumber, ProductName, 
        InCoterm, HSCode ,
        Currency,Floor,PS,ClientCode,PcCode,DescriptionFrench,
        DescriptionEnglish,Department,Unit,UcpUsd,TcpUsd,UspUsd,
        TspUsd,LotNumber,Brochure,Remarks,Catalog,
        CS,CE,Iso,Maf,Warranty,LeadTime,Fator,
    } = inputValue;
    const [ Model, setModel ] = useState("");
    const [ Brand, setBrand ] = useState("");
    const [ Category, setCategory ] = useState("");
    const [ selectSupplier, setselectSupplier ] = useState("");
    const [ Dimension, setDimension ] = useState({
        height : "", width : "", length : "", weight : ""
    });
    const [ Quantity, setQuantity ] = useState("");
    const [ Unitconstprice,setUnitconstprice ] = useState("");
    const [ DateSelect, setDateSelect ] = useState("");
    const [ CountryOfOrigin, setCountryOfOrigin ] = useState("");
    const { supplierListall, viewProduct, categoryAllList, settingsObjectIdWise, brandlistall, settinglists, selectedBrandWiseModel, accessToken, device_id, loaderMain,ClearFormSet } = useSelector((state) => state.adminReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ AvatarImage, setAvatarImage ] = useState("");
    const [ AvatarImageId, setAvatarImageId ] = useState("");
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ ImportPorductLoader, setImportPorductLoader ] = useState(false);
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbols = ["e", "E", "+", "-"];
    // error field
    const [ errorProductName, setErrorProductName ] = useState("");
    const [ errorModel, setErrorModel ] = useState("");
    const [ errorBrand, setErrorBrand ] = useState("");
    const [ errorSupplier, setErrorSupplier ] = useState("");
    const [ errorPInumber, setErrorPInumber ] = useState("");
    const [ errorInCoterm, setErrorInCoterm ] = useState("");
    const [ errorCurrency, setErrorCurrency ] = useState("");
    const [ errorHSCode, setErrorHSCode ] = useState("");
    const [ errorQuantity, setErrorQuantity ] = useState("");
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorDesciption, setErrorDesciption ] = useState("");
    const [ errorAmount, setErrorAmount ] = useState("");
    const [ errorDimension, setErrorDimension ] = useState("");
    const [ errorInPcCode, setErrorPcCode ] = useState("");

    // unique array function
    function getUniqueArray(arr, propertyName) {
        const uniqueValues = new Set();
        const result = [];
      
        for (const item of arr) {
          const value = item[propertyName];
      
          if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            result.push(item);
          }
        }
      
        return result;
    };

    // get product details
    useEffect(() => {
        if(Location === "edit-product") {
            if(params?.roomId) {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "get_product_detail",
                    "request" : { 
                        "product_id" : params?.roomId, 
                        "DeviceId" : device_id
                    }
                };
                wsSend_request(websocket, param);
            };
        };
    }, [ params?.roomId, Location ]);

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_brand_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, [ ]);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_settings_list",
            "request" : { 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, [ ]);

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "supplier_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, [ ]);

    // get brand list
    useEffect(() => {
        if(Brand !== ""){
            setTimeout(() => {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "brand_model_list",
                    "request" : { 
                        "brand_id" : Brand, 
                        "limit" : 5000, 
                        "page_no" : 1,
                    }
                };
                wsSend_request(websocket, param);
            }, 200);
        }
    }, [ Brand ]);

    // get brand list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_category_list",
            "request" : { 
                "filter" :"all",
                "limit" : 5000, 
                "page_no" : 1,
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, [ ]);

    const [ UpdateMetaFields, setUpdateMetaFields ] = useState([]);
    let MetaFieldsArray = [...UpdateMetaFields];

    useEffect(() => {
        if(Location === "edit-product") {
            if(viewProduct) {
                setAvatarImage(viewProduct?.avatar === null ? "" : viewProduct?.view_thumbnail_url);
                setInputValue({ 
                    ...inputValue,
                    PInumber : viewProduct?.pi_no, 
                    ProductName : viewProduct?.name, 
                    InCoterm: viewProduct?.incoterm, 
                    HSCode: viewProduct?.hs_code, 
                    Currency: viewProduct?.currency,
                    Floor: viewProduct?.floor,
                    PS: viewProduct?.ps,
                    ClientCode: viewProduct?.client_code,
                    PcCode: viewProduct?.pc_code,
                    DescriptionFrench: viewProduct?.description_french,
                    DescriptionEnglish: viewProduct?.description_english,
                    Department: viewProduct?.department,
                    Unit: viewProduct?.unit,
                    UcpUsd: viewProduct?.ucp_usd,
                    TcpUsd: viewProduct?.tcp_usd,
                    UspUsd: viewProduct?.usp_usd,
                    TspUsd: viewProduct?.tsp_usd,
                    LotNumber: viewProduct?.lot_number,
                    Brochure: viewProduct?.brochure,
                    Remarks: viewProduct?.remarks,
                    Catalog: viewProduct?.catalog,
                    CS: viewProduct?.cs,
                    CE: viewProduct?.ce,
                    Iso: viewProduct?.iso,
                    Maf: viewProduct?.maf,
                    Warranty: viewProduct?.warranty,
                    LeadTime: viewProduct?.lead_time,
                    Fator: viewProduct?.fator,
                });
                setQuantity(viewProduct?.quantity);
                setUnitconstprice(viewProduct?.unit_cost_price);
                setCategory(viewProduct?.category)
                setCountryOfOrigin(viewProduct?.country_of_origin);
                setDateSelect(viewProduct.date === null ? "" :new Date(moment(Date(viewProduct.date)).format('YYYY/MM/DD hh:mm:ss')));
                setModel(viewProduct?.model); 
                setBrand(viewProduct?.brand);
                setselectSupplier(viewProduct?.supplier);

                const MetaFieldsArraysss = []
                
                if(viewProduct?.meta_field?.length > 0) {
                    viewProduct?.meta_field?.map((elm) => {
                        if(settingsObjectIdWise[elm?.key] !== undefined) {
                            if(elm?.key === settingsObjectIdWise[elm?.key].slug) {
                                let myobj = {
                                    id: settingsObjectIdWise[elm?.key]?.id,
                                    key : elm?.key,
                                    value : elm?.value
                                };
                                MetaFieldsArraysss.push(myobj);
                                setUpdateMetaFields(getUniqueArray(MetaFieldsArraysss, 'id'));
                            };
                        };
                    });
                };

                if(viewProduct?.dimensions === null || viewProduct?.dimensions === undefined) {
                    setDimension({...Dimension, 
                        length : "", 
                        height : "", 
                        width : "", 
                        weight : "", 
                    });
                } else {
                    setDimension({...Dimension, 
                        length : JSON.parse(viewProduct?.dimensions?.replace(/'/g, '"'))?.length, 
                        height : JSON.parse(viewProduct?.dimensions?.replace(/'/g, '"'))?.height, 
                        width : JSON.parse(viewProduct?.dimensions?.replace(/'/g, '"'))?.width, 
                        weight : JSON.parse(viewProduct?.dimensions?.replace(/'/g, '"'))?.weight
                    });
                }
            };
        };
    }, [ viewProduct ]);

    useEffect(() => {
        settinglists?.list?.map((element) => {
            let myobj = {
                id: element?.id,
                key : element?.slug,
                value : ""
            };
            MetaFieldsArray.push(myobj);
            setUpdateMetaFields(getUniqueArray(MetaFieldsArray, 'id'));
        });
    }, [ settinglists?.list ]);

    // onchange metafield update
    const UpdateValue = (e, id) => {
        const UpdateMetaFieldsArray = UpdateMetaFields.map((elm) => {
            if(elm?.id === id) {
                return {
                    ...elm,
                    value: e.target.value,
                }
            }else
                return elm;
        });
        setUpdateMetaFields(UpdateMetaFieldsArray);
    };

    // metabox form
    const MetaFiledForms = () => {
        return(<div className="col-md-12">
            {UpdateMetaFields.length > 0 && (<div className="col-md-12">
                <div className="form-group">
                    <label>Fields</label>
                    <div className="settingsligs">
                        <div className="row">
                            {UpdateMetaFields?.map((elm, index) => {
                                return(<div className="col-md-4" key={index}>
                                    <div className="formInput" style={{ marginBottom: "15px" }}>
                                        <label>{elm?.key}</label>
                                        <input type="text" className="form-control" value={elm?.value} onChange={(e) => UpdateValue(e, elm?.id)} onKeyDown={EmptySpaceFieldValid} placeholder={`Enter Value`} />
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>)}
        </div>)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // select country wise currency
    useEffect(() => {
        if(CountryOfOrigin !== "") {
            Countrycurrency.filter((elm) => {
                if(elm?.country === CountryOfOrigin) {
                    setInputValue({...inputValue, Currency:  elm?.currency_code});
                    setErrorCurrency("");
                }
            });
        };
    }, [ CountryOfOrigin ]);

    // select brands
    const SelectOnnchangeBrand = (e) => {
        if(e.target.value !== "") {
            setBrand(e.target.value);
            setModel("");
            CheckValid(e.target.value, {type: 'Brand', error: setErrorBrand});
        } else {
            setBrand("");
            setModel("");
            CheckValid(e.target.value, {type: 'Brand', error: setErrorBrand});
        }
    };

    // select brands
    const SelectOnnchangeModel = (e) => {
        if(e.target.value !== "") {
            setModel(e.target.value);
            CheckValid(e.target.value, {type: 'Model', error: setErrorModel});
        } else {
            setModel("");
            CheckValid(e.target.value, {type: 'Model', error: setErrorModel});
        }
    };

    // select category
    const SelectOnnchangeCategory = (e) => {
        if(e.target.value !== "") {
            setCategory(e.target.value);
        } else {
            setCategory("");
        }
    };


    // select supplier
    const OnSelectSupplier = (e) => {
        if(e.target.value !== "") {
            setselectSupplier(e.target.value);
            CheckValid(e.target.value, {type: 'SupplierSelect', error: setErrorSupplier});
        } else {
            setselectSupplier("");
            CheckValid(e.target.value, {type: 'SupplierSelect', error: setErrorSupplier});
        }
    };

    // upload product image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse) {
            setUploadFileLoader(false);
            setAvatarImage(fileresponse?.view_thumbnail_url);
            setAvatarImageId(fileresponse?.id);
        };
    };

    const fileInput = document.getElementById("file-input");
    // import product list
    const ImportPorductListx = async (e) => {
        setImportPorductLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await ImportProductAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            if(Object.keys(fileresponse.data).length === 0 ){
                fileInput.addEventListener("click", function() {
                    fileInput.value = "";
                });
                setImportPorductLoader(false);
                toast.error("Product import failed!");
            } else{
                fileInput.addEventListener("click", function() {
                    fileInput.value = "";
                });
                if(fileresponse.data.status === true) {
                    if(Object.keys(fileresponse?.data?.data?.failed).length !== 0 && Object.keys(fileresponse?.data?.data?.updated).length === 0 && fileresponse?.data?.data?.added?.length === 0) {
                        setImportPorductLoader(false);
                        toast.success(fileresponse?.data?.msg);
                        setShowModal({
                            ...showModal,
                            open: !showModal?.open,
                            title: "Export Products",
                            subtitle: "Export Products",
                            description: `${fileresponse?.data?.data?.total_products - fileresponse?.data?.data?.total_added} products imported failed. Please click on the export button to download those ${fileresponse?.data?.data?.total_products - fileresponse?.data?.data?.total_added} products.`,
                            modalType: "no form",
                            button: "Export",
                            Data: fileresponse?.data?.data?.failed
                        });
                    } else if(Object.keys(fileresponse?.data?.data?.updated).length !== 0 && Object.keys(fileresponse?.data?.data?.failed).length === 0 && fileresponse?.data?.data?.added?.length === 0) {
                        setImportPorductLoader(false);
                        toast.success("Product updated successfully.");
                        setShowModal({
                            ...showModal,
                            open: !showModal?.open,
                            title: "Products Update",
                            subtitle: "Products Update",
                            description: `${fileresponse?.data?.data?.total_products - fileresponse?.data?.data?.total_added} products updated successfully. Please click on the export button to download those ${fileresponse?.data?.data?.total_products - fileresponse?.data?.data?.total_added} products.`,
                            modalType: "no form",
                            button: "Export",
                            Data: fileresponse?.data?.data?.updated
                        });
                    } else if(Object.keys(fileresponse?.data?.data?.updated).length !== 0 && Object.keys(fileresponse?.data?.data?.failed).length !== 0) {
                        setImportPorductLoader(false);
                        toast.success("Product added successfully.");
                        setShowModal({
                            ...showModal,
                            open: !showModal?.open,
                            title: "Products Update and Export",
                            subtitle: "Products Update and Export",
                            description: `Products with import failures and updated items are available for download. Click the export button for failed imports or download the updated list.`,
                            modalType: "no form",
                            button: "Export",
                            Data: fileresponse?.data?.data
                        });
                    } else {
                        setImportPorductLoader(false);
                        toast.success(fileresponse?.data?.msg);
                        setTimeout(() => {
                            Navigate(PRODUCT_MANAGEMENT_URL);
                        }, 200);
                    };
                } else {
                    setImportPorductLoader(false);
                    toast.error(fileresponse.data.msg);
                }
            }
        };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setAvatarImage("");
        setAvatarImageId("");
    };

    // add new category
    const AddCategoryFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Add Category",
            subtitle: "Add Category",
            description: ``,
            modalType: "form",
            button: "Yes"
        });
    };

    // add new brand
    const AddBrandFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Create Brand",
            subtitle: "Create Brand",
            description: ``,
            modalType: "form",
            button: "Yes",
        });
    };

    // add new model
    const AddModelFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Create Model",
            subtitle: "Create Model",
            description: ``,
            modalType: "form",
            button: "Yes",
        });
    };

    // add new supplier
    const AddSupplierFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Create Supplier",
            subtitle: "Create Supplier",
            description: ``,
            modalType: "form",
            button: "Yes",
        });
    };

    // create product
    const MatatagsAddToProduct = UpdateMetaFields.map(({key, value}) => ({key, value}));
    const MatatagsAddToProductValueCheck = MatatagsAddToProduct.filter((elm) => elm?.value !== "");
    const CreateProduct = () => {
        if(Location === "edit-product") {
            if(ProductName !== "" && Model !== "" && Brand !== "" ) {
                let param;
                if(MatatagsAddToProductValueCheck?.length > 0) {
                    param = { 
                        "transmit": "broadcast", 
                        "url": "update_product",
                        "request" : {
                            "product_id" : params?.roomId,
                            "pi_no" : PInumber, 
                            "avatar" : AvatarImageId === "" ? null : AvatarImageId,
                            "name": ProductName, 
                            "brand" : Brand,
                            "model" : Model, 
                            "quantity" : Quantity, 
                            "supplier" : selectSupplier, 
                            "unit_cost_price" : Unitconstprice, 
                            "incoterm" : InCoterm, 
                            "hs_code" : HSCode, 
                            "dimensions" : Dimension, 
                            "currency" : Currency,
                            "floor" : Floor,
                            "ps" : PS,
                            "date" : DateSelect,
                            "client_code" : ClientCode,
                            "pc_code" : PcCode,
                            "description_french" : DescriptionFrench,
                            "description_english" : DescriptionEnglish,
                            "department" : Department,
                            "unit" : Unit,
                            "ucp_usd" : UcpUsd,
                            "tcp_usd" : TcpUsd,
                            "usp_usd" : UspUsd,
                            "tsp_usd" : TspUsd,
                            "lot_number" : LotNumber,
                            "brochure" : Brochure,
                            "remarks" : Remarks,
                            "country_of_origin" : CountryOfOrigin,
                            "catalog" : Catalog,
                            "cs" : CS,
                            "ce" : CE,
                            "iso" : Iso,
                            "maf" : Maf,
                            "warranty" : Warranty,
                            "lead_time" : LeadTime,
                            "category" : Category,
                            "fator" : Fator,
                            "DeviceId" : device_id,
                            "meta_field": MatatagsAddToProductValueCheck
                        } 
                    };
                } else {
                    param = { 
                        "transmit": "broadcast", 
                        "url": "update_product",
                        "request" : {
                            "product_id" : params?.roomId,
                            "pi_no" : PInumber, 
                            "avatar" : AvatarImageId === "" ? null : AvatarImageId,
                            "name": ProductName, 
                            "brand" : Brand,
                            "model" : Model, 
                            "quantity" : Quantity, 
                            "supplier" : selectSupplier, 
                            "unit_cost_price" : Unitconstprice, 
                            "incoterm" : InCoterm, 
                            "hs_code" : HSCode, 
                            "dimensions" : Dimension, 
                            "currency" : Currency,
                            "floor" : Floor,
                            "ps" : PS,
                            "date" : DateSelect,
                            "client_code" : ClientCode,
                            "pc_code" : PcCode,
                            "description_french" : DescriptionFrench,
                            "description_english" : DescriptionEnglish,
                            "department" : Department,
                            "unit" : Unit,
                            "ucp_usd" : UcpUsd,
                            "tcp_usd" : TcpUsd,
                            "usp_usd" : UspUsd,
                            "tsp_usd" : TspUsd,
                            "lot_number" : LotNumber,
                            "brochure" : Brochure,
                            "remarks" : Remarks,
                            "country_of_origin" : CountryOfOrigin,
                            "catalog" : Catalog,
                            "cs" : CS,
                            "ce" : CE,
                            "iso" : Iso,
                            "maf" : Maf,
                            "warranty" : Warranty,
                            "lead_time" : LeadTime,
                            "category" : Category,
                            "fator" : Fator,
                            "DeviceId" : device_id
                        } 
                    };
                }
                
                wsSend_request(websocket, param);
            } else {
                CheckValid(ProductName, {type: 'ProductName', error: setErrorProductName});
                // CheckValid(Unitconstprice, {type: 'Unitconstprice', error: setErrorUnitConstPrice});
                CheckValid(Model, {type: 'Model', error: setErrorModel});
                CheckValid(Brand, {type: 'Brand', error: setErrorBrand});
                // CheckValid(Quantity, {type: 'Quantity', error: setErrorQuantity});
                // CheckValid(Currency, {type: 'Currency', error: setErrorCurrency});
                // CheckValid(selectSupplier, {type: 'SupplierSelect', error: setErrorSupplier});
            };
        } else {
            if(ProductName !== "" && Model !== "" && Brand !== "" && PcCode !== "") {
                let paramRequest;
                if(MatatagsAddToProductValueCheck?.length > 0) {
                    paramRequest = {
                        "pi_no" : PInumber, 
                        "avatar" : AvatarImageId,
                        "name": ProductName, 
                        "brand" : Brand,
                        "model" : Model, 
                        "quantity" : Quantity, 
                        "supplier" : selectSupplier, 
                        "unit_cost_price" : Unitconstprice, 
                        "incoterm" : InCoterm, 
                        "hs_code" : HSCode, 
                        "dimensions" : Dimension, 
                        "DeviceId" : device_id,
                        "currency" : Currency,
                        "floor" : Floor,
                        "ps" : PS,
                        "date" : DateSelect,
                        "client_code" : ClientCode,
                        "pc_code" : PcCode,
                        "description_french" : DescriptionFrench,
                        "description_english" : DescriptionEnglish,
                        "department" : Department,
                        "unit" : Unit,
                        "ucp_usd" : UcpUsd,
                        "tcp_usd" : TcpUsd,
                        "usp_usd" : UspUsd,
                        "tsp_usd" : TspUsd,
                        "lot_number" : LotNumber,
                        "brochure" : Brochure,
                        "remarks" : Remarks,
                        "country_of_origin" : CountryOfOrigin,
                        "catalog" : Catalog,
                        "cs" : CS,
                        "ce" : CE,
                        "iso" : Iso,
                        "maf" : Maf,
                        "warranty" : Warranty,
                        "lead_time" : LeadTime,
                        "category" : Category,
                        "fator" : Fator,
                        "meta_field" : MatatagsAddToProductValueCheck
                    }; 
                } else {
                    paramRequest = {
                        "pi_no" : PInumber, 
                        "avatar" : AvatarImageId,
                        "name": ProductName, 
                        "brand" : Brand,
                        "model" : Model, 
                        "quantity" : Quantity, 
                        "supplier" : selectSupplier, 
                        "unit_cost_price" : Unitconstprice, 
                        "incoterm" : InCoterm, 
                        "hs_code" : HSCode, 
                        "dimensions" : Dimension, 
                        "DeviceId" : device_id,
                        "currency" : Currency,
                        "floor" : Floor,
                        "ps" : PS,
                        "date" : DateSelect,
                        "client_code" : ClientCode,
                        "pc_code" : PcCode,
                        "description_french" : DescriptionFrench,
                        "description_english" : DescriptionEnglish,
                        "department" : Department,
                        "unit" : Unit,
                        "ucp_usd" : UcpUsd,
                        "tcp_usd" : TcpUsd,
                        "usp_usd" : UspUsd,
                        "tsp_usd" : TspUsd,
                        "lot_number" : LotNumber,
                        "brochure" : Brochure,
                        "remarks" : Remarks,
                        "country_of_origin" : CountryOfOrigin,
                        "catalog" : Catalog,
                        "cs" : CS,
                        "ce" : CE,
                        "iso" : Iso,
                        "maf" : Maf,
                        "warranty" : Warranty,
                        "lead_time" : LeadTime,
                        "category" : Category,
                        "fator" : Fator
                    }; 
                };
                
                const paramfilter = Object.entries(paramRequest).filter((elm) => {
                    if(elm[1] !== "") {
                        return elm;
                    }
                });
                let param = { 
                    "transmit": "broadcast", 
                    "url": "add_product",
                    "request" : Object.fromEntries(paramfilter)
                };
                wsSend_request(websocket, param);
            } else {
                CheckValid(ProductName, {type: 'ProductName', error: setErrorProductName});
                // CheckValid(Unitconstprice, {type: 'Unitconstprice', error: setErrorUnitConstPrice});
                CheckValid(Model, {type: 'Model', error: setErrorModel});
                CheckValid(Brand, {type: 'Brand', error: setErrorBrand});
                // CheckValid(Quantity, {type: 'Quantity', error: setErrorQuantity});
                // CheckValid(Currency, {type: 'Currency', error: setErrorCurrency});
                // CheckValid(selectSupplier, {type: 'SupplierSelect', error: setErrorSupplier});
                CheckValid(PcCode, {type: 'PcCode', error: setErrorPcCode});
            };
        };  
    };

    useEffect(()=>{
        if(ClearFormSet.url === "update_product" && ClearFormSet.action === true){
            Navigate(PRODUCT_MANAGEMENT_URL);
            ClearForm();
        }else if(ClearFormSet.url === "add_product" && ClearFormSet.action === true){
            Navigate(PRODUCT_MANAGEMENT_URL);
            Dispatch(getBrandsWiseModelList({}));
            ClearForm();
        }
    },[ClearFormSet]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // clear form
    const ClearForm = () => {
        setInputValue({...inputValue, PInumber : "", ProductName : "",  InCoterm: "", HSCode: "", Currency: "", Floor: "", PS: "", ClientCode: "", PcCode: "", DescriptionFrench: "", DescriptionEnglish: "", Department: "", Unit: "", UcpUsd: "", TcpUsd:"", UspUsd:"", TspUsd:"", LotNumber: "", Brochure: "", Remarks: "", Catalog: "", CS: "", CE: "", Iso: "", Maf: "", Warranty: "", LeadTime: "", Category: "", Fator: ""});
        setDimension({...Dimension, length : "", height : "", width : "", weight : ""});
        setAvatarImage("");
        setAvatarImageId("");
        setModel("");
        setBrand("");
        setselectSupplier("");
        setUnitconstprice("");
        setQuantity("");
        setUpdateMetaFields([]);
    };

    return(<React.Fragment>
        <div className="header_back header_back_with_btn" style={{ justifyContent: Location === "edit-product" ? "end" : "space-between" }}>
            <div className="headerbtngroups">
                {Location === "add-product" && (<label className="btn import_product" disabled={ImportPorductLoader}>
                    <input type="file" accept=".xlsx" id="file-input" onChange={(e) => ImportPorductListx(e)}/>
                    {ImportPorductLoader && (<BtnLoader />)}
                    <i className="bi bi-upload"></i>&nbsp;Import Product
                </label>)}
                <a href={SampleFile} className="btn export_btncmn">
                    <i className="bi bi-download"></i> &nbsp;Sample File
                </a>
            </div>
            <BackButton targeturl={PRODUCT_MANAGEMENT_URL} title={"Back"} />
        </div>
        <section className="dashboard_wrapper_form" >
            <div className="scrollform">
                <Scrollbars 
                    style={{ height: "calc(100vh - 150px)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="scrollformCustom">
                        <div className="row" style={{ margin: "0 auto" }}>
                            <div className="col-md-4">
                                <div className="form-group fileUploadingform">
                                    <label>Product Image</label>
                                    {(AvatarImage === "" || AvatarImage === null) ? (<><input type="file" className="form-control" onChange={(e) => UploadImage(e)} disabled={UploadFileLoader} accept=".png, .jpg, .jpeg" />
                                        {UploadFileLoader && <div className="filUploading">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            Please wait, your file is uploading.
                                        </div>}
                                    </>) : (<div className="uploadedImagewrps">
                                            <AvatarGet type="product" url={AvatarImage} />
                                            <div className="closeIocn" onClick={() => RemoveUploadImage()}>
                                                <i className="bi bi-x-circle-fill"></i>
                                            </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={ProductName}
                                    className={errorProductName !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Product Name"
                                    label="Product Name <span class='redmednstar'>*</span>"
                                    name="ProductName"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'ProductName', error: setErrorProductName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorProductName}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={PcCode}
                                    className={errorInPcCode !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Pc Code"
                                    label="Pc Code <span class='redmednstar'>*</span>"
                                    name="PcCode"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'PcCode', error: setErrorPcCode})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInPcCode}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={PInumber}
                                    className={errorPInumber !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Purchase Invoice Number"
                                    label="Purchase Invoice Number"
                                    name="PInumber"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'PInumber', error: setErrorPInumber})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorPInumber}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Unit cost price</label>
                                    <div className="formInput">
                                        <input 
                                            type="number" 
                                            className="form-control inputBoxs"
                                            placeholder="Enter Unit cost price"
                                            value={Unitconstprice}
                                            onChange={(e) => setUnitconstprice(e.target.value.replace(/^0/, ""))}
                                            onKeyDown={(e) =>
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Quantity</label>
                                    <div className="formInput">
                                        <input 
                                            type="number" 
                                            className="form-control inputBoxs"
                                            placeholder="Enter Quantity"
                                            value={Quantity}
                                            onChange={(e) => setQuantity(e.target.value.replace(/^0/, ""))}
                                            onKeyDown={(e) =>
                                                exceptThisSymbols.includes(e.key) && e.preventDefault()
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Category</label>
                                    <div className="filedaddwraps">
                                        <select className="form-control" value={Category} onChange={(e) => SelectOnnchangeCategory(e)}>
                                            <option value="">Select Category</option>
                                            {categoryAllList?.map((elm, index) => {
                                                if(elm?.is_active === true) {
                                                    return(<option value={elm?.id} key={index}>{elm?.name}</option>)
                                                }
                                            })}
                                        </select>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Category</Tooltip>}
                                        >
                                            <button type="button" className="btn addbtn" onClick={(e) => AddCategoryFnct(e)}>
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Brand <span className='redmednstar'>*</span></label>
                                    <div className="filedaddwraps">
                                        <select className="form-control" value={Brand} onChange={(e) => SelectOnnchangeBrand(e)}>
                                            <option value="">Select Brand</option>
                                            {brandlistall?.map((elm, index) => {
                                                if(elm?.is_active === true) {
                                                    return(<option value={elm?.id} key={index}>{elm?.name}</option>)
                                                }
                                            })}
                                        </select>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Brand</Tooltip>}
                                        >
                                            <button type="button" className="btn addbtn" onClick={(e) => AddBrandFnct(e)}>
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    {errorBrand !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorBrand}</div>)}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Model <span className='redmednstar'>*</span></label>
                                    <div className="filedaddwraps">
                                        <select className="form-control" value={Model} onChange={(e) => SelectOnnchangeModel(e)}>
                                            <option value="">Select Model</option>
                                            {selectedBrandWiseModel?.map((elm, index) => {
                                                if(elm?.is_active === true) {
                                                    return(<option value={elm?.id} key={index}>{elm?.name}</option>)
                                                }
                                            })}
                                        </select>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Model</Tooltip>}
                                        >
                                            <button type="button" className="btn addbtn" onClick={() => AddModelFnct()}>
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    {errorModel !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorModel}</div>)}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Supplier</label>
                                    <div className="filedaddwraps">
                                        <select className="form-control" value={selectSupplier} onChange={(e) => OnSelectSupplier(e)}>
                                            <option value="">Select Supplier</option>
                                            {supplierListall?.map((elm, index) => {
                                                if(elm?.is_active === true) {
                                                    return(<option value={elm?.id} key={index}>{elm?.company}</option>)
                                                }
                                            })}
                                        </select>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Supplier</Tooltip>}
                                        >
                                            <button type="button" className="btn addbtn" onClick={() => AddSupplierFnct()}>
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    {errorSupplier !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorSupplier}</div>)}
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Amount}
                                    className={errorAmount !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Amount"
                                    label="Amount"
                                    name="Amount"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Amount', error: setErrorAmount})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorAmount}
                                />
                            </div> */}
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={InCoterm}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter In-Coterm"
                                    label="In-coterm"
                                    name="InCoterm"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Enter Country Of Origin</label>
                                    <select className="form-control" value={CountryOfOrigin} onChange={(e) => setCountryOfOrigin(e.target.value)}>
                                        <option>Seelct </option>
                                        {Country?.getAllCountries().map((elm, index) => {
                                            return(<option key={index} value={elm?.name}>
                                                {elm?.name}
                                            </option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Currency}
                                    className={errorCurrency !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Currency"
                                    label="Currency"
                                    name="Currency"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'Currency', error: setErrorCurrency})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorCurrency}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Floor}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Floor"
                                    label="Floor"
                                    name="Floor"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={PS}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter PS"
                                    label="PS"
                                    name="PS"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                 <div className="form-group">
                                    <label>Date</label>
                                    <div className="formInput">
                                        <DatePicker 
                                            selected={DateSelect} 
                                            onChange={(date) => setDateSelect(date)} 
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth 
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Enter Date"
                                            // maxDate={newDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={ClientCode}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Client Code"
                                    label="Client Code"
                                    name="ClientCode"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={HSCode}
                                    className={errorHSCode !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter HS-Code"
                                    label="HS-Code"
                                    name="HSCode"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'HSCode', error: setErrorHSCode})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorHSCode}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={DescriptionEnglish}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Description English"
                                    label="Description English"
                                    name="DescriptionEnglish"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={DescriptionFrench}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Description French"
                                    label="Description French"
                                    name="DescriptionFrench"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Department}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Department"
                                    label="Department"
                                    name="Department"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Unit}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Unit"
                                    label="Unit"
                                    name="Unit"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={UcpUsd}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Ucp Usd"
                                    label="UCP USD"
                                    name="UcpUsd"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={TcpUsd}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter TCP USD"
                                    label="TCP USD"
                                    name="TcpUsd"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={UspUsd}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter USP USD"
                                    label="USP USD"
                                    name="UspUsd"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={TspUsd}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter TSP USD"
                                    label="TSP USD"
                                    name="TspUsd"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={LotNumber}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Lot Number"
                                    label="Lot Number"
                                    name="LotNumber"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Brochure}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Brochure"
                                    label="Brochure"
                                    name="Brochure"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Remarks}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Remarks"
                                    label="Remarks"
                                    name="Remarks"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Catalog}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Catalog"
                                    label="Catalog"
                                    name="Catalog"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={CS}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter CS"
                                    label="CS"
                                    name="CS"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={CE}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter CE"
                                    label="CE"
                                    name="CE"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Iso}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Iso"
                                    label="Iso"
                                    name="Iso"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Maf}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Maf"
                                    label="Maf"
                                    name="Maf"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Warranty}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Warranty"
                                    label="Warranty"
                                    name="Warranty"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={LeadTime}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Lead Time"
                                    label="Lead Time"
                                    name="LeadTime"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Fator}
                                    className={errorInCoterm !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Fator"
                                    label="Fator"
                                    name="Fator"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'InCoterm', error: setErrorInCoterm})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorInCoterm}
                                />
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>Dimension</label>
                                    <div className="dimession_wraps">
                                        <input type="text" className="form-control" value={Dimension?.height} onChange={(e) => setDimension({...Dimension, height: e.target.value})} placeholder="Enter height"/>
                                        <input type="text" className="form-control" value={Dimension?.width} onChange={(e) => setDimension({...Dimension, width: e.target.value})} placeholder="Enter width"/>
                                        <input type="text" className="form-control" value={Dimension?.length} onChange={(e) => setDimension({...Dimension, length: e.target.value})} placeholder="Enter length"/>
                                        <input type="text" className="form-control" value={Dimension?.weight} onChange={(e) => setDimension({...Dimension, weight: e.target.value})} placeholder="Enter weight"/>
                                    </div>
                                </div>
                            </div>
                            {MetaFiledForms()}
                            <div className="col-md-12">
                                <div className="form-group-btn" onClick={() => CreateProduct()} style={{ textAlign: "right" }}>
                                    <button className="btn addbtncmn">{Location === "edit-product" ? "Save" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && (<CommonLoader />)}
        </section>
        {showModal?.open && (<CommonModel fromUrl={"product"} showModal={showModal} setShowModal={setShowModal} />)}
    </React.Fragment>)
};

export default AddEditProduct;