import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CART_MANAGEMENT_URL, VIEW_PROFILE_URL } from "./constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector } from "react-redux";
import SidebarToggle from "../../assets/img/toogle_btn.png";
import CommonModel from "../Common/CommonModel";

const Header = (props) => {
    const { setShowSidebar, showSidebar } = props;
    const Location = useLocation().pathname?.split("/")[1].replaceAll("-", " ");
    const Navigate = useNavigate();
    const { selfInfo, addToCartLists } = useSelector((state) => state.adminReducers);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // logout function
    const LogoutFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    }

    return(<div className="head_section">
        {window.screen.width < 901 && (<button className="toogle_bar_btn" onClick={() => setShowSidebar(!showSidebar)}>
            <img src={SidebarToggle} alt="toggle" />
        </button>)}
        <h4>{Location}</h4>
        <div className="dropdownwraps">
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Your Basket</Tooltip>}
            >
                <button type="button" className="add_cartbtn" onClick={() => Navigate(CART_MANAGEMENT_URL)}>
                    <i className="bi bi-cart"></i>
                    {addToCartLists?.length > 0 && (<Badge bg="secondary">{addToCartLists?.length}</Badge>)}
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Your Profile</Tooltip>}
            >
                <div className="user_DETAILS" onClick={() => Navigate(VIEW_PROFILE_URL)}>
                    <AvatarGet type={"avatar"} url={null} />
                    {window.screen.width > 540 && (<React.Fragment>
                        {(selfInfo?.first_name !== null && selfInfo?.last_name !== null) ? selfInfo?.first_name + " " + selfInfo?.last_name : selfInfo.email}
                    </React.Fragment>)}
                </div>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Logout</Tooltip>}
            >
                <button type="button" className="btn logoutbtn" onClick={() => LogoutFnct()}>
                    <i className="bi bi-box-arrow-right"></i>
                </button>
            </OverlayTrigger>
        </div>
        {showModal?.open && (<CommonModel showModal={showModal} setShowModal={setShowModal} />)}
    </div>)
}

export default Header;