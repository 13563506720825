/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import { ADD_PRODUCT_MANAGEMENT_URL, VIEW_PRODUCT_MANAGEMENT_URL, EDIT_PRODUCT_MANAGEMENT_URL } from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import { addToCartList, addMultipleQauntityCart, removeMultipleQauntityCart, getBrandsWiseModelList,RemoveCartList,ClearFormSetFutios } from "../../redux/actions/adminActions";
import CommonModel from "../Common/CommonModel";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ProductMangement = () => {
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, productList, brandlistall, modellistall, device_id,addToCartLists,categoryAllList,supplierListall } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const [ GetFilterCategoryIds, setGetFilterCategoryIds ] = useState("");
    const [ GetFilterbrandIds, setGetFilterbrandIds ] = useState("");
    const [ GetFiltersupplierIds, setGetFiltersupplierIds ] = useState("");

    // brand name get
    const BrandNameGet = (brand_id) => {
        return brandlistall?.filter((elm) => brand_id === elm?.id)[0]?.name;
    };

    // model name get
    const ModelNameGet = (model_id) => {
        return modellistall?.filter((elm) => model_id === elm?.id)[0]?.name;
    };
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // add new vendor
    const AddVendorFnct = () => {
        Navigate(ADD_PRODUCT_MANAGEMENT_URL);
        Dispatch(getBrandsWiseModelList({}));
    };

    
    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_brand_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_model_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_category_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "supplier_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, []);

    // get product list
    useEffect(() => {
        let paramRequest = { 
            "filter" : userType, 
            "brand_id" : GetFilterbrandIds,
            "category_id" : GetFilterCategoryIds,
            "supplier_id" : GetFiltersupplierIds,
            "limit" : userLimit, 
            "page_no" : currentPage, 
            "search" : userSearch.trim(),
            "DeviceId" : device_id
        };
        const paramfilter = Object.entries(paramRequest).filter((elm) => {
            if(elm[1] !== "") {
                return elm;
            }
        });
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : Object.fromEntries(paramfilter)
        };
        wsSend_request(websocket, param);
    }, [ userSearch, userType, userLimit, currentPage,GetFilterbrandIds,GetFilterCategoryIds,GetFiltersupplierIds ]);


    const ResetFnct = () => {
        setUserSearch("");
        setUserType("all");
        setUserLimit(10);
        setCurrentPage(1);
        setGetFilterCategoryIds("");
        setGetFilterbrandIds("");
        setGetFiltersupplierIds("");
    };

    // delete product
    const DeleteProductFnct = (data_) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Delete Product",
            subtitle: "Delete Product",
            description: `Do you really want to delete <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    };

    const ProductActive = (data_, type, booleancheck) =>{
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Active Product",
            description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    }

    const AddToCartProducts = (product) => {
        const CartCehck = addToCartLists.filter((elm) => elm?.data?.id === product?.id);
        return(<React.Fragment>
            {CartCehck?.length > 0 ? (<div className="productQuantyset">
                <button className="btn removeQuantity" onClick={() => CartCehck[0]?.data?.quantity === product?.quantity ? Dispatch(RemoveCartList(CartCehck[0])) : Dispatch(removeMultipleQauntityCart({product: CartCehck[0], mainQuantity: product?.quantity, mainCost: product?.unit_cost_price}))}>
                    <i className="bi bi-dash-circle-fill"></i>
                </button>
                <div className="productQuantyBox">{CartCehck[0]?.data?.quantity}</div>
                <button className="btn addQuantity" onClick={() => Dispatch(addMultipleQauntityCart({product: CartCehck[0], mainQuantity: product?.quantity, mainCost: product?.unit_cost_price }))}>
                    <i className="bi bi-plus-circle-fill"></i>
                </button>
            </div>) : (<button type="button" className="btn addtocartebtn" onClick={() => Dispatch(addToCartList({productz: product, mainQuantity: product?.quantity, mainCost: product?.unit_cost_price}))} >
                <i className="bi bi-cart-fill"></i> Add To Cart
            </button>)}
        </React.Fragment>)
    };

    return(<section className="dashboard_wrapper">
        <div className="filter_sectio">
            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label>
                            Search 
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Name and Item code, Pc code, Invoice number, Hc code</Tooltip>}
                            >
                                <i className="bi bi-info-circle-fill"></i>
                            </OverlayTrigger>
                        </label>
                        <React.Fragment>
                            {userSearch !== undefined && (<div className="searchBoxwithbtn" style={{ width: "100%" }}>
                                <input type="text" className="form-control" value={userSearch} onChange={(e) => setUserSearch(e.target.value)} placeholder={`Search...`}/>
                                {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                    <path d="M21 21l-6 -6"></path>
                                </svg>) : (<i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>)}
                            </div>)}
                        </React.Fragment>
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="form-group">
                        <label>Limit</label>
                        <select className="form-control userLimitselect" style={{ width: "100%" }} value={userLimit} onChange={(e) => setUserLimit(parseInt(e.target.value))}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>Status</label>
                        <select className="form-control activestatusselet" style={{ width: "100%" }} value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Category</label>
                        <select className="form-control activestatusselet" value={GetFilterCategoryIds} onChange={(e) => setGetFilterCategoryIds(e.target.value)} style={{ width: "100%" }}>
                            <option value="">Select Category</option>
                            {categoryAllList?.length > 0 && categoryAllList.map((elm,index)=>{
                                return(<option  value={elm.id} key={index}>{elm.name}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Supplier</label>
                        <select className="form-control activestatusselet" value={GetFiltersupplierIds} onChange={(e) => setGetFiltersupplierIds(e.target.value)} style={{ width: "100%" }}>
                            <option value="">Select Supplier</option>
                            {supplierListall?.length > 0 && supplierListall.map((elm,index)=>{
                                return(<option  value={elm.id} key={index}>{elm.name}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Brand</label>
                        <select className="form-control activestatusselet" value={GetFilterbrandIds} onChange={(e) => setGetFilterbrandIds(e.target.value)} style={{ width: "100%" }}>
                            <option value="">Select Brand</option>
                            {brandlistall?.length > 0 && brandlistall?.map((elm,index)=>{
                                return(<option  value={elm.id} key={index}>{elm.name}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-2" style={{ width: "auto",paddingRight:"0px" }}>
                    <div className="form-group">
                        <label>&nbsp;</label>
                        <button className="btn head_reset_btn" style={{ marginLeft:"0px" }} onClick={() => ResetFnct()}>
                            <i className="bi bi-x-circle"></i>
                            Reset
                        </button>
                    </div>
                </div>
                <div className="col-md-2" style={{ width: "auto" }}>
                    <div className="form-group">
                        <label>&nbsp;</label>
                        <button className="btn head_cmn_btn" style={{ marginLeft:"0px" }} onClick={() => AddVendorFnct()}>
                            <i className="bi bi-plus-circle"></i>
                            Add Product
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="tableContent" style={{ height: window.screen.width > 540 ? "calc(100vh - 255px)" : "calc(100vh - 143px)" }}>
            <Scrollbars 
                style={{ height: productList?.pagination?.total_records > 10 ? (window.screen.width < 767 ? "calc(100% - 10px)" : "calc(100% - 38px)") : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Item No</th>
                                <th>Pc Code</th>
                                <th>Picture </th>
                                <th>Product Name</th>
                                <th>Model</th>
                                <th>Brand</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList && productList?.list?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                    <tr>
                                        <td>{user?.item_code}</td>
                                        <td>{user?.pc_code}</td>
                                        <td>
                                            <AvatarGet type="product" url={user?.view_thumbnail_url} />
                                        </td>
                                        <td>{user?.name}</td>
                                        <td>{ModelNameGet(user?.model) === null ? "-" : ModelNameGet(user?.model)}</td>
                                        <td>{BrandNameGet(user?.brand) === null ? "-" : BrandNameGet(user?.brand)}</td>
                                        <td>{user?.quantity === null ? "-" : user?.quantity}</td>
                                        <td>{user?.unit_cost_price === null ? "-" : "$"+user?.unit_cost_price}</td>
                                        <td>
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-product"))) && (<CommonToggle 
                                                valueToggle={user?.is_active}
                                                setValueToggle={() => ProductActive(user, "is_active", user?.is_active)}
                                                name={""}
                                            />)}
                                        </td>
                                        <td className="respoactionproduct">
                                            <button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${VIEW_PRODUCT_MANAGEMENT_URL}/${user?.id}`)} >
                                                <i className="bi bi-eye"></i>
                                            </button>
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-product"))) && (<button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${EDIT_PRODUCT_MANAGEMENT_URL}/${user?.id}`)} >
                                                <i className="bi bi-pencil-square"></i>
                                            </button>)}
                                            {(selfInfo?.is_admin || selfInfo?.permissions?.includes("delete-product")) && (<button type="button" className="btn actiontblebtn" onClick={() => DeleteProductFnct(user)}>
                                                <i className="bi bi-trash-fill" style={{ color: "red" }}></i>
                                            </button>)}
                                            {(selfInfo?.is_admin || selfInfo?.permissions?.includes("add-cart")) && (<>
                                                {(user?.quantity !== null && user?.quantity !== 0 && user?.unit_cost_price !== null) && AddToCartProducts(user)}
                                            </>)}
                                        </td>
                                    </tr>
                                </React.Fragment>)
                            })}
                            {productList && productList?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={10}>Products Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {productList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={productList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
</section>)
}

export default ProductMangement;